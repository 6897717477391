export default {
  api: {
    auth: {
      key:
				'Wwqv4Euhlr+HF0G+FgSE4VwERGWN4368OuIONYxjfBcg07zF+niT0uj0Tl/hxngGahdbApprnDTZXtg/bjw+AA==',
			secret: 'ceca2b321834708288a7',
      jwt_secret: 'GOgOg0H1r3',
    },
    host: process.env.REACT_APP_API || 'http://localhost:8787',
  }
}