import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';


export default (props) => {
  return(
    <Grid
      style={{ height: "40vh" }}
      container
      alignItems="center"
      justifyContent="center"
    >
      <Grid item>
        <Box sx={{ display: 'flex' }}>
          <CircularProgress size={props.size} />
        </Box>
      </Grid>
    </Grid>
  )
}
