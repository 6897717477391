import { Avatar, Card, CardContent, Grid, Typography } from '@mui/material';

export const TotalCount = (props) => (
  <Card {...props}>
    <CardContent>
      <Grid
        container
        sx={{ justifyContent: 'center' }}
      >
        <Grid item>
          <Typography
            color="rgba(0, 0, 0, 0.87)"
            gutterBottom
            variant="overline"
          >
            {props.text}
          </Typography>
          <Typography
            color="rgba(0, 0, 0, 0.87)"
            variant="h4"
          >
            {props.value}
          </Typography>
        </Grid>
      </Grid>
    </CardContent>
  </Card>
);