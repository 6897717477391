import Snackbar from '@mui/material/Snackbar';

export function SnackbarCenter (props) {
  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      open={props.open}
      autoHideDuration={3000}
      message={props.message}
      onClose={props.onClose}
    />
  )
}
