export default (startColor, endColor, steps, thickness = 1) => {
    var start = {
      'Hex': startColor,
      'R': parseInt(startColor.slice(1,3), 16),
      'G': parseInt(startColor.slice(3,5), 16),
      'B': parseInt(startColor.slice(5,7), 16)
    }
    var end = {
      'Hex': endColor,
      'R': parseInt(endColor.slice(1,3), 16),
      'G': parseInt(endColor.slice(3,5), 16),
      'B': parseInt(endColor.slice(5,7), 16)
    }
    let diffR = end['R'] - start['R'];
    let diffG = end['G'] - start['G'];
    let diffB = end['B'] - start['B'];

    let stepsRGB = [];
    let stepsR = [];
    let stepsG = [];
    let stepsB = [];

    for(var i = 0; i <= steps; i++) {
      stepsR[i] = start['R'] + ((diffR / steps) * i);
      stepsG[i] = start['G'] + ((diffG / steps) * i);
      stepsB[i] = start['B'] + ((diffB / steps) * i);
      stepsRGB[i] = `rgba(${Math.round(stepsR[i])}, ${Math.round(stepsG[i])}, ${Math.round(stepsB[i])}, ${thickness})`;
    }
    return stepsRGB;
}
