import './App.css';

import { useState, useEffect } from 'react';
import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
} from 'chart.js';
import { Line, Pie } from 'react-chartjs-2';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import DateRangePicker from '@mui/lab/DateRangePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DateAdapter from '@mui/lab/AdapterMoment';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import {
  getUsersInCompany,
  getCompany,
  countThreadsPerCompany,
  contactsPerCompany,
  contactsPerCompanyThisMonth,
  getUserSettings,
  getCalendarEventPerDateRange
} from './utils/helper';
import { TotalCount } from './component/total-count';
import LoadingCircle from './component/loading-circle';
import { SnackbarCenter } from './component/snackbar';
import JWT_decode from 'jwt-decode';
import moment from 'moment';
import momentTz from 'moment-timezone';
import { styled } from '@mui/material/styles';

ChartJS.register(
  ArcElement,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const decodedJWT = JWT_decode(
  new URLSearchParams(window.location.search).get('token'));

function App() {
  const [data, setData] = useState({
    labels: [],
    list: [],
    inboundArray: null,
    outboundArray: null,
    newContacts: 0,
    totalInbound: 0,
    totalOutbound: 0,
    totalUsers:0,
    getUsersPerCompanyTitle: '',
    threadsByUser: null,
    decodedJWT: null,
    presetDateRange: 'last 30 days',
    isLoading: false,
    showSnackbar: false,
    messageSnackbar: '',
    interviewsPerDateRange: [],
    interviewsThisMonth: [],
    userTimeZone: '',
    scheduledInterviewPerDateRange: 0,
    totalInterviews: 0,
    scheduledInterviews: null
  });

  const [filter, setFilter] = useState([
    moment().subtract(30, 'days'),
    moment()]
  );

  useEffect(() => {
    (async () => {
      let jwtIsAdmin = decodedJWT.params.isAdmin;
      let companyName = '';

      if (jwtIsAdmin) {
        let company = await getCompany(
          decodedJWT.params.selectedCompany);

        companyName = company.name;
      }

      setData((data) => {
        return {
          ...data,
          getUsersPerCompanyTitle: companyName,
        }
      });

      await getAllData();
    })();
  }, []);

  const handleSearch = () => {
    getAllData();
  }

  const handleSnackbarClose = () => {
    setData({
      ...data,
      showSnackbar: false,
      messageSnackbar: '',
    });
  }

  const SearchButton = styled(Button)({
    backgroundColor: 'rgb(76, 175, 80)',
    borderColor: 'rgb(76, 175, 80)',
    '&:hover': {
      backgroundColor: 'rgba(76, 175, 80, 0.7)',
      borderColor: 'rgba(76, 175, 80, 0.7)',
      boxShadow: 'none',
    },
  })

  const getAllData = async () => {

    // check for valid dates
    if (!filter[0].isValid() || !filter[1].isValid()) {
      setData({
        ...data,
        showSnackbar: true,
        messageSnackbar: 'Date should be valid. Check inputs.',
      });

      return;
    }

    // check startDate should not pass endDate
    if (filter[1].diff(filter[0], 'days') < 0) {
      setData({
        ...data,
        showSnackbar: true,
        messageSnackbar: 'Start Date must be earlier than End Date.',
      });

      return;
    }

    // limit data request to 12 months only
    if (filter[1].diff(filter[0], 'days') > 365) {
      setData({
        ...data,
        showSnackbar: true,
        messageSnackbar: 'Date should not exceed 12 months.',
      });

      return;
    }

    // show data is loading
    setData({
      ...data,
      isLoading: true,
    });

    const startDate = filter[0].format('YYYY-MM-DD');
    const endDate = filter[1].format('YYYY-MM-DD');
    const startOfMonth = moment().startOf('month').format('YYYY-MM-DD');
    const endOfMonth = moment().endOf('month').format('YYYY-MM-DD');

    try {
      const { data: { data: totalUsers } } =
        await getUsersInCompany(decodedJWT.params.selectedCompany);

      let { inboundArray, outboundArray, yearArray, threadsByUser } =
        await countThreadsPerCompany(
          decodedJWT.params.selectedCompany, startDate, endDate);

      let { yearArray: sortedDates, dataByMonth: sortedCounts } =
        await contactsPerCompany(
          decodedJWT.params.selectedCompany,
          startDate, endDate);

      // get scheduled interviews this month
      let {
        calendarEvent: interviewsThisMonth,
      } = await getCalendarEventPerDateRange(decodedJWT.params.selectedCompany,
        startOfMonth, endOfMonth);

      // get scheduled interviews per date range
      let {
        calendarEvent: interviewsPerDateRange,
        scheduledInterviewPerDateRange
      } = await getCalendarEventPerDateRange(decodedJWT.params.selectedCompany,
        startDate, endDate);

      // get total scheduled interviews
      const {
        calendarEvent: totalInterviews
      } = await getCalendarEventPerDateRange(decodedJWT.params.selectedCompany);

      const newContacts = await contactsPerCompanyThisMonth(
        decodedJWT.params.selectedCompany);

      // get total inbound and outbound
      let totalInbound = inboundArray.reduce((a,b) => a + b, 0);
      let totalOutbound = outboundArray.reduce((a,b) => a + b, 0);

      const { zone: userTimeZone } = await getUserSettings();

      setData({
        ...data,
        totalUsers: totalUsers.length,
        inboundArray,
        outboundArray,
        totalInbound,
        totalOutbound,
        threadsByUser,
        sortedDates,
        sortedCounts,
        yearArray,
        interviewsPerDateRange,
        newContacts,
        isLoading: false,
        userTimeZone,
        scheduledInterviewPerDateRange,
        totalInterviews: totalInterviews.length,
        interviewsThisMonth
      });
    } catch (e) {
      console.error(e);
      setData({
        ...data,
        isLoading: false,
      });
    }
  }

  const handleDateRange = (event) => {
    const selectValue = event.target.value;
    const today = moment();

    switch(selectValue) {
      case 'last 30 days':
        setFilter([
          today.clone().subtract(30, 'days'),
          today]);
        break;
      case 'last 60 days':
        setFilter([
          today.clone().subtract(60, 'days'),
          today]);
        break;
      case 'this year':
        setFilter([
          today.clone().startOf('year'),
          today]);
        break;
      case 'last year':
        setFilter([
          today.clone().subtract(1, 'years').startOf('year'),
          today.clone().subtract(1, 'years').endOf('year')]);
        break;
      case 'custom':
        setFilter([
          today,
          today])
        break;
    }

    setData({...data, presetDateRange: selectValue});
  }

  let rows = [
    'Company',
    'Contact',
    'Date',
  ];

  if (!decodedJWT.params.isAdmin) {
    // remove company column
    rows.shift();
  }

  let tableContents = (
    <>
      <TableHead>
        <TableRow>
          {rows.map(row => (<TableCell>{row}</TableCell>))}
        </TableRow>
      </TableHead>
      <TableBody style={{ position: 'relative' }}>
        {data.interviewsPerDateRange.length ? data.interviewsPerDateRange.map((row) => {
          return (
            <TableRow key={row.id}>
              {decodedJWT.params.isAdmin && <TableCell>{row.contact.company.name}</TableCell>}
              <TableCell>{row.contact.name}</TableCell>
              <TableCell>
                {momentTz(row.start).tz((data.userTimeZone || '')).format('ddd MM/DD/YY [at] h:mmA z')}
                {/* {moment(row.created_at).utc().utcOffset(row.timezone).format('LLLL')} */}
              </TableCell>
            </TableRow>
          )
        }) : (
          <div style={{ width: '100%', position: 'absolute' }}>
            <strong>
              Have you implemented scheduled interview? Set it up and conduct one right now!
            </strong>
          </div>
        )}
      </TableBody> 
    </>
  )

  return (
    <div className="App">
      { data.isLoading ? <LoadingCircle size={80} /> : <Container maxWidth={false}>
        <SnackbarCenter
          open={data.showSnackbar}
          message={data.messageSnackbar}
          onClose={handleSnackbarClose}
        />
        <Typography variant={"h5"}>
          {data.getUsersPerCompanyTitle}
        </Typography>
        <Grid style={{ textAlign: 'right', marginTop: '10px' }}
          container
          spacing={2}
          direction="row"
          justifyContent="flex-end"
          alignItems="center">
          <Grid item lg={2} md={3} xs={3}>
            <FormControl fullWidth sx={{ m: 1 }}>
              <InputLabel id="date-range-selector">Preset Date Range</InputLabel>
              <Select
                labelId="date-range-selector"
                id="date-range-selector"
                value={data.presetDateRange}
                onChange={(event) => {handleDateRange(event)}}
                label="Preset Date Range"
              >
                <MenuItem value="custom">Custom Dates</MenuItem>
                <MenuItem value="last 30 days">Last 30 Days</MenuItem>
                <MenuItem value="last 60 days">Last 60 Days</MenuItem>
                <MenuItem value="this year">This Year</MenuItem>
                <MenuItem value="last year">Last Year</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item lg={3} md={5} xs={5}>
            <LocalizationProvider dateAdapter={DateAdapter}>
              <DateRangePicker
                startText="Start Date"
                endText="End Date"
                value={filter}
                onChange={(filter) => {
                  setData({ ...data, presetDateRange: 'custom' })
                  setFilter(filter);
                }}
                renderInput={(startProps, endProps) => (
                  <React.Fragment>
                    <TextField {...startProps} />
                    <Box sx={{ mx: 2 }}> to </Box>
                    <TextField {...endProps} />
                  </React.Fragment>
                )}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item lg={1} md={2} xs={2}>
            <SearchButton
              variant="contained"
              disabled={data.isLoading}
              onClick={() => { handleSearch() }}>Search</SearchButton>
          </Grid>
        </Grid>
        <Grid style={{ marginTop: '10px' }} container spacing={4}>
          <Grid item lg={2} md={4} xs={12}>
            <TotalCount text="Inbound Messages" value={data.totalInbound} />
          </Grid>
          <Grid item lg={2} md={4} xs={12}>
            <TotalCount text="Outbound Messages" value={data.totalOutbound} />
          </Grid>
          <Grid item lg={2} md={4} xs={12}>
            <TotalCount text="Users Count" value={data.totalUsers} />
          </Grid>
          <Grid item lg={2} md={4} xs={12}>
            <TotalCount text="Contacts this Month" value={data.newContacts} />
          </Grid>
          <Grid item lg={2} md={4} xs={12}>
            <TotalCount text="Interviews this Month" value={data.interviewsThisMonth.length} />
          </Grid>
          <Grid item lg={2} md={4} xs={12}>
            <TotalCount text="Scheduled Interviews" value={data.totalInterviews} />
          </Grid>
        </Grid>
        <Grid style={{ marginTop: '10px' }} container xs={12}>
          <Grid style={{ marginTop: '20px' }} container spacing={4} xs={12}>
            <Grid item lg={6} md={6} xs={12}>
              <Grid>
                <Grid item xs={12} style={{ textAlign: 'center', marginBottom: '40px' }}>
                  <span
                    style = {{
                      textTransform: 'none',
                      fontSize: '12px',
                      fontFamily: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
                      color: '#666',
                      fontWeight: 'bold',
                      padding: '10px',
                      lineHeight: '1.2px',
                    }}
                  >
                    Incoming Scheduled Interviews
                  </span>
                </Grid>
                <Grid item>
                  <TableContainer
                    component={Paper}
                    sx={{ maxHeight: 500, height: 300 }}
                  >
                    <Table
                      stickyHeader
                      aria-label="simple table"
                      style={{ position: 'relative' }}
                    >
                      {tableContents}
                    </Table>
                  </TableContainer>
                </Grid>
              </Grid>
            </Grid>
            <Grid item lg={6} md={6} xs={12}>
              <Line
                options={{
                  responsive: true,
                  scales: { yAxis: { beginAtZero: true } },
                  plugins: {
                    legend: {
                      position: 'top',
                    },
                    title: {
                      display: true,
                      text: 'Scheduled Interviews per Month',
                    },
                  }
                }}
                data={{
                  labels: data.sortedDates,
                  datasets: [
                    {
                      label: 'Scheduled Interviews',
                      data: data.scheduledInterviewPerDateRange,
                      borderColor: 'rgb(76, 175, 80)',
                      backgroundColor: 'rgb(76, 175, 80)',
                    }
                  ],
                }}
              />
            </Grid>
          </Grid>
          <Grid style={{ marginTop: '20px' }} container spacing={4} xs={12}>
            <Grid item lg={6} md={6} xs={12}>
              <Line
                options={{
                  responsive: true,
                  scales: { yAxis: { beginAtZero: true } },
                  plugins: {
                    legend: {
                      position: 'top',
                    },
                    title: {
                      display: true,
                      text: 'New Contacts per Month',
                    },
                  }
                }}
                data={{
                  labels: data.sortedDates,
                  datasets: [
                    {
                      label: 'New Contacts',
                      data: data.sortedCounts,
                      borderColor: 'rgb(76, 175, 80)',
                      backgroundColor: 'rgb(76, 175, 80)',
                    }
                  ],
                }}
              />
            </Grid>
            <Grid item lg={6} md={6} xs={12}>
              <Line
                options={{
                  responsive: true,
                  plugins: {
                    legend: {
                      position: 'top',
                    },
                    title: {
                      display: true,
                      text: 'Messages per Month',
                    },
                  },
                  scales: { yAxis: { beginAtZero: true } },
                }}
                data={{
                  labels: data.yearArray,
                  datasets: [
                    {
                      label: 'Outbound',
                      data: data.outboundArray,
                      borderColor: 'rgb(76, 175, 80)',
                      backgroundColor: 'rgb(76, 175, 80)',
                    },
                    {
                      label: 'Inbound',
                      data: data.inboundArray,
                      borderColor: 'rgb(14, 94, 68)',
                      backgroundColor: 'rgb(14, 94, 68)',
                    },
                  ],
                }}
              />
            </Grid>
          </Grid>
          <Grid style={{ marginTop: '20px' }} container space={4} xs={12}>
            <Grid item lg={6} md={6} xs={12}>
              <Line
                options={{
                  responsive: true,
                  plugins: {
                    legend: {
                      position: 'top',
                    },
                    title: {
                      display: true,
                      text: 'Outbound Messages per User per Month',
                    },
                  },
                  scales: { yAxis: { beginAtZero: true } },
                }}
                data={{
                  labels: data.yearArray,
                  datasets: data.threadsByUser || []
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Container>}
    </div>
  );
}

export default App;
