import apiCore from './api-core';
import gradient from './gradient';
import moment from 'moment';

export const MONTH_ARRAY = Array.from({length: 12}, (e, i) => {
  return new Date(null, i + 1, null).toLocaleDateString("en", {month: "long"});
});

const convertToDataProp = (data) => {

  let keyList = Object.keys(data);
  let borderColorList = gradient('#4caf50', '#093b2a', keyList.length);
  let backgroundColorList = gradient('#4caf50', '#093b2a', keyList.length);

  let dataset = keyList.map((key, index) => {
    return {
      label: key,
      data: data[key],
      borderColor: borderColorList[index],
      backgroundColor: backgroundColorList[index],
    }
  });

  return dataset;
}

const getYearArray = (startDate, endDate) => {
  let start = moment(startDate);
  let end = moment(endDate);
  let yearArray = [];

  while(!(start.isSame(end, 'month') && start.isSame(end, 'year'))) {
    yearArray.push(start.format('MMM YYYY'));
    start.add(1, 'months');
  }
  // add end manually since the while loop stops before endDate
  yearArray.push(end.format('MMM YYYY'));

  return yearArray
}

export const getCompany = async (company_id) => {

  const { data: { data: [ response ] }} = await apiCore.get(
    `/api/v1/companies/${company_id}`
  );

  return response;
}

export const getUsersPerCompany = async (company_id) => {
  const { data: { data: listUsers } } = await apiCore.get(
    `/api/v1/users`
  );

  // Todo: process all below in the backend
  let usersPerCompany = listUsers.filter((value) => value.company_id === company_id);

  // Group by categories and then count
  let categories = usersPerCompany.reduce((acc, cur) => {
    acc[cur.department_name] = (acc[cur.department_name] || 0) + 1
    return acc;
  }, {});

  return categories;
}

export const getUsersInCompany = async (companyId) => {
  return await apiCore.get(`/api/v1/users/get-by-company-id/${companyId}`);
}

export const countThreadsPerCompany = async (company_id, startDate, endDate) => {
  const {data: {data}} = await apiCore.get(
    `/api/v1/conversation/${company_id}?dateRange=${startDate}_${endDate}`
  );

  let yearArray = getYearArray(startDate, endDate);

  // initialize arrays with 0
  let inboundArray = new Array(yearArray.length).fill(0);
  let outboundArray = new Array(yearArray.length).fill(0);
  let threadsByUser = {'Bot Response': new Array(yearArray.length).fill(0)};

  data.forEach((value) => {
    const {created_at, direction, user} = value;
    let dateIndex = yearArray.indexOf(moment(created_at).format('MMM YYYY'))

    if(direction === 'inbound') {
      inboundArray[dateIndex] += 1;
    } else {
      if (user) {
        if (!threadsByUser[user.name]) {
          threadsByUser[user.name] = new Array(yearArray.length).fill(0);
        }

        threadsByUser[user.name][dateIndex] += 1;
      } else {
        threadsByUser['Bot Response'][dateIndex] += 1;
      }

      outboundArray[dateIndex] += 1;
    }
  });

  threadsByUser = convertToDataProp(threadsByUser);

  return { inboundArray, outboundArray, yearArray, threadsByUser };
}

export const contactsPerCompany = async (company_id, startDate, endDate) => {
  let filterVal =
  `{"field":"contact.created_at","filterVal":"${startDate}_${endDate}",` +
  `"includeArchive":null, "excludeUnknown":true,` +
  `"sortBy":"created_at","sortDirection":"desc"}`;
  // numberOfRows and page really doesn't matter since we are using the `allRows` property of the response
  let page = `{"numberOfRows":null,"page":null}`;
  const {data: {data}} = await apiCore.get(
    `/api/v1/companies/${company_id}/${page}/${filterVal}/contacts`
  );

  let yearArray = getYearArray(startDate, endDate);

  // count values and map to MMM YYYY
  // e.g. {May 2021: 5, Nov 2022: 16, ...}
  let dataByMonth = new Array(yearArray.length).fill(0);
  data.rows.forEach((value) => {
    let dateIndex = yearArray.indexOf(moment(value.created_at).format('MMM YYYY'));

    dataByMonth[dateIndex] += 1;
  });

  return {yearArray, dataByMonth};
}

export const contactsPerCompanyThisMonth = async (company_id) => {
  let startDate = moment().startOf('month').format('YYYY-MM-DD');
  let endDate = moment().endOf('month').format('YYYY-MM-DD');
  let filterVal =
  `{"field":"contact.created_at","filterVal":"${startDate}_${endDate}",` +
  `"includeArchive":null, "excludeUnknown":true,` +
  `"sortBy":"created_at","sortDirection":"desc"}`;
  // numberOfRows and page really doesn't matter since we are using the `allRows` property of the response
  let page = `{"numberOfRows":null,"page":null}`;

  const {data: {data}} = await apiCore.get(
    `/api/v1/companies/${company_id}/${page}/${filterVal}/contacts`
  );

  return data.rows.length || 0;
}

export const getCalendarEventPerDateRange = async (companyId, startDate = null, endDate = null) => {
  let queryString = `/api/v1/calendarevent`
  let scheduledInterviewPerDateRange = [];

  if (companyId) queryString += `?companyId=${companyId}`

  if(startDate && endDate) {
    queryString += `&startDate=${startDate}&endDate=${endDate}`
  }

  const {data: { data }} = await apiCore.get(queryString)

  if(startDate && endDate) {
    // get the count of scheduled interview per date range
    let yearArray = getYearArray(startDate, endDate);
    scheduledInterviewPerDateRange = new Array(yearArray.length).fill(0);
    data.forEach((value) => {
      let dateIndex = yearArray.indexOf(moment(value.created_at).format('MMM YYYY'));

      scheduledInterviewPerDateRange[dateIndex] += 1;
    });
  }
  
  return { calendarEvent: data, scheduledInterviewPerDateRange };
}

export const getUserSettings = async (userId) => {
  let queryString = `/api/v1/integrations/settings`

  const {data: { data }} = await apiCore.get(queryString)
  return data;
}