import axios from 'axios';
import JWT_decode from 'jwt-decode';

import config from './config';

export const secret = {
  'x-api-key': config.api.auth.key,
  'x-api-secret': config.api.auth.secret,
}

const url_params = new URLSearchParams(window.location.search);
const token = url_params.get('token');
const { authorization } = JWT_decode(token);

const axiosInstance = axios.create({
  baseURL: config.api.host,
  headers:{
    ...secret,
    authorization
  }
});

export default axiosInstance
